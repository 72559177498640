import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import HomePage from "@/components/views/HomePage";
import LoginPage from "@/components/views/LoginPage";
import AdminPage from "@/components/views/AdminPage";
import UserAdminPage from "@/components/views/UserAdminPage.vue";

//routing
const routes = [
	{path: '/app', component: HomePage},
	{path: '/admin', component: AdminPage},
	{path: '/user-admin', component: UserAdminPage},
	{path: '/', component: LoginPage},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router);
app.mount('#app')
