<template>
    <form>
        <div class="form-fields">
            <div class="form-field">
                <input type="text" name="filter_text" placeholder="Enter Keyword" v-model="store.filters.text"/>
            </div>
            <div class="form-field">
                <select @change="campaign_select($event)" v-model="store.selected_campaign">
                    <option value="0">Select Campaign</option>
                    <option v-for="campaign in store.campaign_list" :value="campaign.id" :key="campaign.id">{{campaign.title}}</option>
                </select>
            </div>
            <div class="form-field">
                <select @change="add_filter($event, 'reps')">
                    <option value="0">Select Reps</option>
                    <option v-for="rep in store.reps" :value="rep.id" :key="rep.id">{{rep.title}}</option>
                </select>
            </div>
            <div class="form-field">
                <select @change="add_filter($event, 'session')">
                    <option value="0">Select Session</option>
                    <option v-for="session in store.sessions" :value="session.id" :key="session.id">{{session.title}}</option>
                </select>
            </div>
            <div class="form-field">
                <select @change="add_filter($event, 'category')">
                    <option value="0">Select Industry</option>
                    <option v-for="category in store.industry_category" :key="category.id">{{category}}</option>
                </select>
            </div>
        </div>
    </form>
    <div v-if="store.filters.reps.length > 0 || store.filters.session.length > 0 || store.filters.category.length > 0" class="filters">
        <span class="reps" v-for="(filter, index) in store.filters.reps" :key="index" data-filter="reps" :data-value="filter.value" @click="remove_filter($event)">{{filter.text}} x</span>
        <span class="session" v-for="(filter, index) in store.filters.session" :key="index" data-filter="session" :data-value="filter.value" @click="remove_filter($event)">{{filter.text}} x</span>
        <span class="category" v-for="(filter, index) in store.filters.category" :key="index" data-filter="category" :data-value="filter.value" @click="remove_filter($event)">{{filter.text}} x</span>
    </div>
</template>

<script>
import {store} from "@/store";
import {globalMixin} from "@/mixins";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Filter",
    mixins:[globalMixin],
    data: function () {
        return {
            store: store
        }
    },
    methods: {
        campaign_select( e ) {
            store.filters.reps = []
            store.filters.session = []
            store.filters.category = []
            store.selected_campaign = parseInt(e.target.value)
        },
        add_filter( e, type ) {
            let value = e.target.value,
                text = e.target.options[e.target.selectedIndex].text,
                data_exist = store.filters[type].some(item => item.value === value );

            if( value == 0 ) {
                return;
            }

            if( !data_exist ) {
                store.filters[type].push({value: value, text: text})
            }
        },
        remove_filter( e ) {
            let filter = e.target.dataset.filter,
                value = e.target.dataset.value,
                filter_index = store.filters[filter].findIndex(item => item.value === value );
            store.filters[filter].splice(filter_index, 1)
        }
    },
    watch: {},
    mounted() {}
}
</script>

<style scoped>
.form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 0 30px;
}

.form-field {
    margin: 0 0 10px
}

.form-field:not(:first-child) {
    margin: 0 0 10px 10px
}

.form-field > * {
    min-width: 200px;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    outline: none;
}

.filters {
    border-radius: 30px;
    margin: 0 0 30px;
}

.filters span {
    display: inline-block;
    background-color: #fff;
    font-size: 11px;
    font-weight: 700;
    padding: 7px 17px;
    border: 1px solid;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 2px 5px;
}

.filters .reps {
    border-color: #e30613;
    color: #e30613;
}

.filters .category {
    border-color: #706f6f;
    color: #706f6f;
}
</style>