<template>
	<Header />
    <main>
		<div class="main-box">
			<div class="client-list">
				<div class="client-wrapper">
					<div style="display: flex; justify-content: flex-start; align-content: center; gap: 30px;">
						<h3>Users</h3>
						<div>
							<input class="searchbox" type="text" name="filter_text" placeholder="Enter Keyword" v-model="filterText"/>
						</div>
						<div class="button button-red" style="display: inline-block; text-align: center; height: 34px; width: 70px; padding: 5px 10px; margin-left: 20px; cursor:pointer;" @click="addNewUser">New</div>
					</div>

					<div class="table-wrapper">
						<table v-if="userslist.length > 0">
							<thead>
								<tr>
									<th>User Name</th>
									<th>Email</th>
									<th style="width: 150px;">Token</th>
									<th style="width: 150px; text-align:center;">Admin</th>
									<th style="width: 150px; text-align:center;">Cmpn Mgr</th>
									<th style="width: 150px; text-align:center;">Disabled</th>
								</tr>
							</thead>
							<tbody v-if="userslist.length > 0">
								<template v-for="(user, user_index) in filteredUsers" :key="user_index">
									<tr class="client-item" @click="activeEditingUserId = user.id">
										<td>{{user.name}}</td>
										<td>{{user.email}}</td>
										<td style="width: 150px;">{{user.token}}</td>
										<td style="width: 150px; text-align:center;">{{user.isAdmin}}</td>
										<td style="width: 150px; text-align:center;">{{user.isCampaignMgr}}</td>
										<td style="width: 150px; text-align:center;">{{user.isDisabled}}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<UserModal v-if="activeEditingUserId !== null"
				:userslist="userslist"
				:campaigns="campaigns"
				:stationgroups="stationgroups"
				:usereditid="activeEditingUserId"
				v-on:closemodal="activeEditingUserId = null"
				v-on:closeupdate="closeUpdate($event)"
			></UserModal>
		</div>
	</main>
</template>

<script>
import Header from "@/components/views/home/Header";
import {store} from "@/store";
import {globalMixin} from "@/mixins";
import UserModal from "@/components/views/home/UserModal.vue";

export default {
	name: "UserAdminPage",
	components: {
		UserModal,
		Header
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
            store: store,
			userslist: [],
			campaigns: [],
			stationgroups: [],
			activeEditingUserId: null,
			filterText: '',
        }
	},
	computed: {
		filteredUsers() {
			let arr = []
			let ftext = this.filterText.toLowerCase()
			this.userslist.forEach(item => {
				if (item.name.toLowerCase().includes(ftext) || item.email.toLowerCase().includes(ftext)) {
					arr.push(item)
				}
			})
			return arr
		},
	},
	methods: {
		closeUpdate(userlist) {
			this.activeEditingUserId = null
			this.userslist = userlist
		},
		addNewUser() {
			this.activeEditingUserId = 0
		},
    },
	watch: {

    },
	mounted() {
		let self = this
		this.getUserAdmin().then(function(response) {
			self.userslist = response.userslist
			self.stationgroups = response.stationgroups
			self.campaigns = response.campaigns
		})
	}
}
</script>

<style scoped>
header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    width: 100%;
    height: 70px;
    padding: 0 50px;
}
main {
    background-color: #e4e4e4;
    min-height: 100vh;
    padding: 100px 0 0;
}
.main-box {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 50px;
}
.client-wrapper:not(:last-child) {
    margin: 0 0 80px;
}
.client-wrapper h3 {
    text-transform: capitalize;
}
.table-wrapper {
    margin: 30px 0 0;
}
table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
table thead {
    background-color: #fff;
}
table th {
    color: #706f6f;
    text-align: left;
    padding: 20px;
}
table tbody {
    display: block;
    background-color: #f4f4f4;
    max-height: 500px;
    overflow: auto;
}
table tbody tr:hover {
    background-color: #e30613;
    cursor: pointer;
}
table tbody tr:hover td {
    color: #fff;
}
table td {
    color: #706f6f;
    font-size: 14px;
    padding: 20px 30px;
    word-break: break-word;
}
.searchbox {
	min-width: 200px;
	height: 40px;
	padding: 10px 20px;
	border: none;
	border-radius: 30px;
	outline: none;
}
</style>