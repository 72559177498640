<template>
    <div class="loader-wrapper" v-if="store.showLoader">
        <div class="loader">
            <div class="box"></div>
            <div class="box"></div>
            <div class="box"></div>
            <div class="box"></div>
        </div>
    </div>
</template>

<script>
import {store} from "@/store";

export default {
    name: "GlobalLoader",
    data() {
        return {
            store: store,
            interval: null,
            current_box: 0
        }
    },
    watch: {
        'store.showLoader'() {
            this.$nextTick(() => {
                if( store.showLoader ) {
                    let boxes = document.querySelectorAll('.box'),
                        box_count = boxes.length

                    this.interval = setInterval(() => {
                        boxes.forEach(item => {
                            item.classList.remove('active')
                        })
                        boxes[this.current_box].classList.add("active")
                        if( this.current_box < (box_count - 1) ) {
                            this.current_box++
                        } else {
                            this.current_box = 0
                        }
                    }, 300)
                } else {
                    clearInterval(this.interval)
                }
            })
        }
    }
}
</script>

<style scoped>
.loader-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
	background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.loader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100px;
    height: 100px;
}

.box {
    background-color: #706f6f;
    width: 48px;
    height: 45px;
}

.box.active {
    background-color: #e30613;
}
</style>