import axios from "axios";
import {store} from "@/store";

export const globalMixin = {
	methods: {
		showKalert(ob) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = ob.type;
			this.store.kalert.shown = true;
		},
		showKalertError(ob) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'danger';
			this.store.kalert.shown = true;
		},
		showKalertSuccess(ob) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'success';
			this.store.kalert.shown = true;
		},
		simplePost(targ, sendob, alertOnError = true) {
			sendob.uid = this.store.user.id;
			sendob.token = this.store.user.password;
			sendob.vue = true;
			return axios.post(targ, JSON.stringify( sendob ), { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (alertOnError === true && ret.error) {
					this.showKalertError(ret.error);
				}
				return ret;
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		async getRepClientList()  {
			store.showLoader = true
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {vue: true, campaignid: store.selected_campaign, uid: store.user.id, token: store.user.password}
			return await axios
				.post(`${store.urlroot}post/get-rep-clientlist.php`, data, headers)
				.then(response => {
					store.industry_category = response.data.industry_categories
					store.sessions = response.data.sessions
					store.reps = response.data.reps
					store.clients = response.data.clients
					return response.data.clients
				})
				.finally(() => {
					store.showLoader = false
				})
		},
		async getCampaigns() {
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {vue: true, func: 'getlist', uid: store.user.id, token: store.user.password}
			return await axios
				.post(`${store.urlroot}post/get-rep-campaignlist.php`, data, headers)
				.then(response => {
					store.campaign_list = response.data.campaigns
					if(!store.selected_campaign) {
						store.selected_campaign = store.campaign_list[0].id
					}
					return response.data.campaigns
				})
		},
		async getClient( client_id ) {
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {vue: true, campaignid: store.selected_campaign, client_id: client_id, uid: store.user.id, token: store.user.password}
			return await axios
				.post(`${store.urlroot}post/get-single-client.php`, data, headers)
				.then(response => {
					store.modal_client_data = response.data;
				})
		},
		async updateClient() {
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = { 	vue: true,
							json: store.modal_client_data.client,
							campaignid: store.selected_campaign,
							client_id: store.modal_client_data.client.id,
							uid: store.user.id,
							token: store.user.password
			}
			return await axios
				.post(`${store.urlroot}post/save-client-info.php`, data, headers)
				.then(response => {
					let ind = store.clients.findIndex(item => item.id === response.data.updatedClientId);
					store.clients[ind] = response.data.client;
				})
		},
		async getProspects()  {
			store.showLoader = true
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {vue: true, campaignid: store.selected_campaign, uid: store.user.id, token: store.user.password}
			return await axios
				.post(`${store.urlroot}post/get-prospects.php`, data, headers)
				.then(response => {
					store.prospects = response.data.prospects
					return response.data.prospects
				})
				.finally(() => {
					store.showLoader = false
				})
		},
		async getUserAdmin() {
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {vue: true, uid: store.user.id, token: store.user.password}
			return await axios
				.post(`${store.urlroot}post/admin/get-reps.php`, data, headers)
				.then(response => {
					return response.data.admin_data
				})
		},
		generateToken(length) {
			let result = '';
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			const charactersLength = characters.length;
			let counter = 0;
			while (counter < length) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
				counter += 1;
			}
			return result;
		},
	}

}