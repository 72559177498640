<template>
    <div class="client-modal show">
        <div v-if="userEditInfo">
            <div class="modal-header">
                <h3>{{userEditInfo.name}}</h3>
            </div>
            <div class="modal-body">
                <div class="details">
                    <div class="details-item">
						<div class="form-field">
							<label for="contact_person" class="font-weight-bold">Name</label>
							<input id="contact_person" type="text" v-model="userEditInfo.name" />
						</div>
						<div class="form-field">
							<label for="contact_email" class="font-weight-bold">Email</label>
							<input id="contact_email" type="text" v-model="userEditInfo.email" />
						</div>
						<div class="form-field">
							<label for="contact_token" class="font-weight-bold">Token</label>
							<input id="contact_token" type="text" v-model="userEditInfo.token" />
						</div>
					</div>
					<div class="details-item">
						<div class="form-field">
							<p><input type="checkbox" name="is_admin" v-model="userEditInfo.isAdmin"  /> <span>Is Admin</span></p>
							<p><input type="checkbox" name="is_campaign_mgr" v-model="userEditInfo.isCampaignMgr"  /> <span>Is Campaign Manager</span></p>
							<p><input type="checkbox" name="login_disabled" v-model="userEditInfo.isDisabled"  /> <span>Login Disabled</span></p>
						</div>
                     </div>
					<div class="details-item">
						<p><span class="font-weight-bold">Station Groups:</span></p>
						<div class="form-field">
							<div v-for="sg in stationgroups" :key="sg.id">
								<p>
									<input type="checkbox" :name="sg.id" v-model="userEditInfo.stationGroups" :value="sg.id"  />
									<span>{{sg.title}}</span>
								</p>
							</div>
						</div>
					</div>
                </div>
				<div class="details">
					<div class="details-item">
						<p><span class="font-weight-bold">Campaigns:</span></p>
						<div class="form-field">
							<div v-for="sg in applicableCampaigns" :key="sg.id">
								<p>
									<input type="checkbox" :name="sg.id" v-model="userEditInfo.campaigns" :value="sg.id"  />
									<span>{{getSGNameFromId(sg.sgid)}} - {{sg.title}}</span>
								</p>
							</div>
						</div>

					</div>
				</div>
            </div>
            <div class="modal-footer">
                <a class="button button-red" href="javascript:void(0)" @click="save_user">Save</a>
                <a class="button button-gray" href="javascript:void(0)" @click="close_modal">Close</a>
            </div>
        </div>
    </div>
</template>

<script>
import {store} from "@/store";
import {globalMixin} from "@/mixins";
import axios from "axios";

export default {
    name: "UserModal",
    mixins: [globalMixin],
	props: {
		usereditid: Number,
		userslist: Object,
		campaigns: Array,
		stationgroups: Array,
	},
    data() {
        return {
            store: store,
			userEditInfo: null,
        }
    },
	computed: {
		applicableCampaigns() {
			return this.campaigns.filter(item => this.userEditInfo.stationGroups.includes(item.sgid))
		},
	},
    methods: {
        close_modal() {
			this.$emit('closemodal')
        },
		updateUserInfoInModal() {
			if(this.usereditid !== null && this.usereditid !== 0) {
				this.userEditInfo = JSON.parse(JSON.stringify(this.userslist.find(item => item.id === this.usereditid)))
			}
			else { //new user etc
				let newToken = this.generateToken(8)
				this.userEditInfo = {
					name: '',
					email: '',
					token: newToken,
					isAdmin: false,
					isCampaignMgr: false,
					isDisabled: false,
					stationGroups: [],
					campaigns: [],
				}
			}
		},
		getSGNameFromId(sgid) {
			if(this.stationgroups.find(item => item.id === sgid)) {
				return this.stationgroups.find(item => item.id === sgid).title
			}
			return ''
		},
        save_user() {
            let self = this
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {
				vue: true,
				uid: store.user.id,
				token: store.user.password,
				userEditInfo: this.userEditInfo,
				userEditId: this.usereditid
			}
			axios.post(`${store.urlroot}post/admin/save-user-info.php`, data, headers).then(response => {
				if(response.data.error) {
					alert(response.data.error)
					return false
				}
				else {
					self.$emit('closeupdate', response.data.admin_data.userslist) //update users list and close modal
				}
			})
        },
    },
    watch: {
		usereditid() {
			this.updateUserInfoInModal()
		},
    },
	mounted() {
		this.updateUserInfoInModal()
	},
    created() {}
}
</script>

<style scoped>
.form-field label {
    display: block;
    margin: 0 0 10px;
}
.form-field input:not([type='checkbox']),
.form-field textarea {
    min-width: 200px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
}
.form-field input:not([type='checkbox']) {
    height: 40px;
}
.form-field select {
    border-radius: 10px;
}
.client-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.2);
}
.client-modal.show {
    visibility: visible;
    opacity: 1;
}
.client-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    background-color: #f4f4f4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    width: calc(100% - 30px);
    min-height: 500px;
	max-height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	overflow-y: auto;
}
.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3aba6f;
    padding: 20px 30px;
}
.modal-header h3 {
    color: #fff;
}
.modal-header .button {
    font-size: 10px;
    height: 40px;
    padding: 0 20px;
}
.modal-body {
    font-size: 14px;
    padding: 50px 30px 20px 30px;
}
.modal-body .form-field:not(:last-child) {
    margin: 0 0 10px
}
.modal-body .details {
    display: flex;
    justify-content: space-between;
}
.modal-body .details > div:not(:last-child) {
    margin-right: 20px;
}
.modal-footer {
	display: flex;
	justify-content: flex-end;
	padding: 10px 10px 20px 10px;
}
.modal-footer a {
	width: 140px;
	margin-left: 20px;
}
</style>