<template>
	<Header />
    <main>
        <Transition name="fade">
            <div class="main-box" v-if="store.campaign_list.length > 0">
				<div style="padding-bottom: 20px;">
					<select v-model="store.selected_campaign">
						<option value="0">Select Campaign</option>
						<option v-for="campaign in store.campaign_list" :value="campaign.id" :key="campaign.id">{{campaign.title}}</option>
					</select>
				</div>
                <div class="client-list">
                    <div class="client-wrapper">
                        <h3>Prospects</h3>
                        <div class="table-wrapper">
                            <table v-if="store && store.prospects && store.prospects.length > 0">
                                <thead>
                                <tr>
                                    <th>Business Info</th>
                                    <th>Contact</th>
                                    <th>Session</th>
                                    <th>Status</th>
                                    <th>Functions</th>
                                </tr>
                                </thead>
                                <tbody>
									<template v-for="(client, client_index) in store.prospects" :key="client_index">
										<transition name="fade">
											<tr class="client-item client-unassigned">
												<td>{{client.title}}<br/>
													{{client.firstName}}<br/>
													{{client.contact_job_title}}</td>
												<td>{{client.phone}}<br/>
													{{client.email}}</td>
												<td>{{client.crmNotes}}</td>
												<td>{{prosStatus(client)}}</td>
												<td>
													<span v-if="prosStatus(client) !== 'Approved'" class="actiontext" @click="actionProspect('approve', client.id)" title="Convert to Client for main list">Approve</span>
													<span v-if="prosStatus(client) !== 'Deleted' && prosStatus(client) !== 'Approved'" class="actiontext" @click="actionProspect('delete', client.id)" title="Delete this prospect">Delete</span>
												</td>
											</tr>
										</transition>
									</template>
                                 </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
	</main>
</template>

<script>
import Header from "@/components/views/home/Header";
import {store} from "@/store";
import {globalMixin} from "@/mixins";
import axios from "axios";

export default {
	name: "AdminPage",
	components: {Header},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
            store: store,
        }
	},
	computed: {

	},
	methods: {
		prosStatus(pros) {
			if(pros.hasBeenConverted === 1) return 'Approved';
			else if(pros.deleted === 1) return 'Deleted';
			else return 'New';
		},
		actionProspect(actiontype, prospectid) { //approve, delete
			store.showLoader = true
			let self = this;
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			let data = {vue: true, prospectid: prospectid, actiontype: actiontype, campaignid: store.selected_campaign, uid: store.user.id, token: store.user.password}
			axios
				.post(`${store.urlroot}post/prospect-action.php`, data, headers)
				// eslint-disable-next-line no-unused-vars
				.then(response => {
					self.getProspects()
				})
		},
    },
	watch: {
        'store.selected_campaign'() {
			store.showLoader = true
			this.getProspects();
        },
    },
	mounted() {
		if(store.campaign_list.length < 1 && store.user.id !== 0) {
			this.getCampaigns()
		}
		else {
			if(store.selected_campaign > 0) {
				this.getProspects();
			}
		}
	}
}
</script>

<style scoped>
header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    width: 100%;
    height: 70px;
    padding: 0 50px;
}
main {
    background-color: #e4e4e4;
    min-height: 100vh;
    padding: 100px 0 0;
}
.main-box {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 50px;
}
.client-wrapper:not(:last-child) {
    margin: 0 0 80px;
}
.client-wrapper h3 {
    text-transform: capitalize;
}
.table-wrapper {
    margin: 30px 0 0;
}
table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
	border-bottom: 1px solid #CCC;
}
table thead {
    background-color: #fff;
}
table th {
    color: #706f6f;
    text-align: left;
    padding: 20px;
}
table tbody {
    display: block;
    background-color: #f4f4f4;
    max-height: 500px;
    overflow: auto;
}
table tbody tr:hover {
    background-color: #e30613;
}
table tbody tr:hover td {
    color: #fff;
}
table td {
    color: #706f6f;
    font-size: 14px;
    padding: 20px 30px;
    word-break: break-word;
}
.actiontext {
	font-size: 11px;
	padding: 5px;
	cursor:pointer;
}
.actiontext:hover {
	text-decoration: underline;
}
</style>