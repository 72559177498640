<template>
	<Header />
    <main>
        <Transition name="fade">
            <div v-if="show_client_box" class="main-box">
                <Downloads v-if="store.user.isAdmin === 1 || store.user.isCampaignMgr === 1" />
                <Filter />
                <div class="client-list">
                    <div v-for="(table, table_index) in tables" class="client-wrapper" :key="table">
                        <h3>{{table.name}} Clients</h3>
                        <div class="table-wrapper">
                            <table v-if="fclients(table_index).length > 0">
                                <thead>
                                <tr>
                                    <th>Business Name</th>
                                    <th>Client Name</th>
                                    <th>Phone</th>
                                    <th>Category</th>
                                    <th>Type</th>
                                    <th>Session</th>
                                    <th>Called</th>
                                </tr>
                                </thead>
                                <tbody v-if="fclients(table_index).length > 0">
									<template v-for="(client, client_index) in fclients(table_index)" :key="client_index">
										<transition name="fade">
											<tr class="client-item client-unassigned" @click="show_modal(client)">
												<td>{{client.title}}</td>
												<td>{{client.firstName}} {{client.lastName}}</td>
												<td>{{client.phone}}</td>
												<td>{{client.industryCategory}}</td>
												<td>{{client.clientCategory}}</td>
												<td>{{client.attendingSession.title}}</td>
												<td>{{client.hasBeenCalled ? "Yes" : "No"}}</td>
											</tr>
										</transition>
									</template>
                                <div class="view-more" v-if="fclients(table_index).length > table.show_count" @click="view_more_table(table_index)">
                                    View More
                                </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal></Modal>
            </div>
        </Transition>
	</main>
</template>

<script>
import Header from "@/components/views/home/Header";
import Filter from "@/components/views/home/Filter";
import {store} from "@/store";
import {globalMixin} from "@/mixins";
import Modal from "@/components/views/home/Modal";
import Downloads from "@/components/views/home/Downloads";

export default {
	name: "HomePage",
	components: {Downloads, Modal, Filter, Header},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
            store: store,
            tables: [
                { name: "assigned", show_count: 50 },
                { name: "unassigned", show_count: 50 },
                { name: "declined", show_count: 50 }
            ],
            show_client_box: true
        }
	},
	computed: {
		assignedClients() {
			let myclients = store.clients.filter(item => item.attendingSession.id != null && parseInt(item.attendingSession.id) > 0 && parseInt(item.attendingSession.id) !== 1417)
			return this.doClientFilter(myclients, this.tables[0].show_count);
		},
		unassignedClients() {
			let myclients = store.clients.filter(item => item.attendingSession.id === null || item.attendingSession.id == 'null')
			return this.doClientFilter(myclients, this.tables[1].show_count);
		},
		declinedClients() {
			let myclients = store.clients.filter(item => item.attendingSession.id === 1417)
			return this.doClientFilter(myclients, this.tables[2].show_count);
		},

	},
	methods: {
		fclients(table_index) {
			if(table_index === 0) return this.assignedClients;
			else if(table_index === 1) return this.unassignedClients;
			else if(table_index === 2) return this.declinedClients;
			return [];
		},
        clear_table_clients() {
			for(let t of this.tables) {
				t.show_count = 50
			}
        },
        view_more_table(table_index) {
			this.tables[table_index].show_count += 50
        },
		doClientFilter(clientarr, maxclientsshown) {
			let arr = []
			clientarr.forEach(item => {
				if(arr.length <= maxclientsshown) {
					let filter_rep_exists = store.filters.reps.length > 0 ? store.filters.reps.some(rep_item => parseInt(rep_item.value) === item.repOwner.id) : true,
						filter_session_exist = store.filters.session.length > 0 ? store.filters.session.some(rep_item => parseInt(rep_item.value) === item.attendingSession.id) : true,
						filter_category_exist = store.filters.category.length > 0 ? store.filters.category.some(rep_item => rep_item.value === item.industryCategory) : true

					if (!filter_rep_exists || !filter_session_exist || !filter_category_exist) {
						return;
					}
					if (!item.title.toLowerCase().includes(store.filters.text.toLowerCase())) {
						return;
					}
					arr.push(item)
				}
			})
			return arr;
		},
        show_modal(client) {
            this.getClient(client.id)
                .then(function() {
					document.querySelector(`.client-modal`).classList.add('show')
                })
        },
    },
	watch: {
        'store.selected_campaign'() {
			store.showLoader = true
			this.clear_table_clients();
			this.getRepClientList();
        },
    },
	mounted() {
		if(store.campaign_list.length < 1 && store.user.id !== 0) {
			this.getCampaigns()
		}
		else {
			if(store.selected_campaign > 0) {
				this.getRepClientList();
			}
		}
        this.show_client_box = true
	}
}
</script>

<style scoped>
header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    width: 100%;
    height: 70px;
    padding: 0 50px;
}
main {
    background-color: #e4e4e4;
    min-height: 100vh;
    padding: 100px 0 0;
}
.main-box {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 50px;
}
.client-wrapper:not(:last-child) {
    margin: 0 0 80px;
}
.client-wrapper h3 {
    text-transform: capitalize;
}
.table-wrapper {
    margin: 30px 0 0;
}
table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
table thead {
    background-color: #fff;
}
table th {
    color: #706f6f;
    text-align: left;
    padding: 20px;
}
table tbody {
    display: block;
    background-color: #f4f4f4;
    max-height: 500px;
    overflow: auto;
}
table tbody tr:hover {
    background-color: #e30613;
    cursor: pointer;
}
table tbody tr:hover td {
    color: #fff;
}
table td {
    color: #706f6f;
    font-size: 14px;
    padding: 20px 30px;
    word-break: break-word;
}
.view-more {
    padding: 5px 12px;
    text-align: center;
    cursor: pointer;
}
.view-more:hover {
    background-color: #e4e4e4;
}
</style>