<template>
    <header>
        <div class="logo">
            <img src="@/assets/images/logo.png">
        </div>
        <div class="logout">
			<router-link to="/app">
				<span class="headnavitem">Main</span>
			</router-link>
			<router-link v-if="store.user.isAdmin === 1 || store.user.isCampaignMgr === 1" to="/admin">
				<span class="headnavitem">Admin</span>
			</router-link>
			<router-link v-if="store.user.isAdmin === 1 && store.user.id === 2855" to="/user-admin">
				<span class="headnavitem">Users</span>
			</router-link>
            <span class="headnavitem" @click="logout">Logout</span>
        </div>
    </header>
</template>

<script>
import {store} from "@/store";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Header",
	data: function () {
		return {
			store: store,
		}
	},
	methods: {
		logout() {
			window.sessionStorage.removeItem('uid');
			window.sessionStorage.removeItem('token');
			store.user.id = 0
			window.location = "/";
		}
	},
}
</script>

<style scoped>
.headnavitem {
	text-decoration: none;
	font-size: 12px;
	padding: 10px;
	cursor: pointer;
}
a {
	text-decoration: none;
	color: inherit;
}
.headnavitem:hover {
	text-decoration: underline;
}
</style>