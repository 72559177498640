<template>
	<AlertTop v-if="store.kalert.shown" :obj="store.kalert"></AlertTop>
    <router-view></router-view>
    <GlobalLoader></GlobalLoader>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import GlobalLoader from "@/components/views/global/GlobalLoader";
import AlertTop from "@/components/AlertTop";

export default {
	name: 'App',
	components: {AlertTop, GlobalLoader},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
            clients: []
		}
	},
    methods: {},
	watch: {

	},
	created() {
		if(window.sessionStorage.getItem('uid')) {
			store.user.id = parseInt(window.sessionStorage.getItem('uid'));
			store.user.password = window.sessionStorage.getItem('token');
			store.user.isAdmin = parseInt(window.sessionStorage.getItem('isadmin'));
			store.user.isCampaignMgr = parseInt(window.sessionStorage.getItem('iscampaignmgr'));
		}
		if(store.user.id === 0) this.$router.push("/");
	},
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin-top: 0;
}

select {
    min-width: 200px;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    outline: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 7px;
    cursor: pointer;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}

#app {

}

.font-weight-bold {
    font-weight: 700;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    border-width: 2px;
    border-style: solid;
    width: 100%;
    height: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.button-red {
    color: #fff;
    background-color: #e30613;
    border-color: #e30613;
}

.button-red-outline,
.button-red:hover {
    background-color: #b70711;
    border-color: #e30613;
}

.button-gray {
    color: #fff;
    background-color: #706f6f;
    border-color: #706f6f;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s ease-in-out;
}
</style>
