<template>
	<main>
        <Transition name="fade">
            <div v-if="show_form" class="login-box">
                <div class="logo">
                    <img src="@/assets/images/logo.png" />
                </div>
                <form @submit.prevent="form_login" autocomplete="off">
                    <div class="form-field">
                        <label for="username">Email</label>
                        <input id="username" type="email" name="email" v-model="email" required/>
                    </div>
                    <div class="form-field">
                        <label for="password">Password</label>
                        <input id="password" type="password" name="password" v-model="password" required/>
                    </div>
                    <div class="form-field">
                        <button class="button button-red">Login</button>
                        <a class="button button-gray" href="javascript:void(0)" @click="get_password">Password Reminder</a>
                    </div>
                </form>
            </div>
        </Transition>
	</main>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";

export default {
	name: "LoginPage",
    mixins: [globalMixin],
	components: {},
	props: {},
	data: function () {
		return {
            show_form: false,
            email: "",
            password: "",
            store: store
        }
	},
	computed: {},
	methods: {
        form_login() {
            store.showLoader = true
			let self = this;
            if( this.email !== "" && this.password !== "" ) {
                this.simplePost(`${store.urlroot}post/do-login.php`, {
                    func: "dologin",
                    email: this.email,
                    pwd: this.password
                })
                .then((response) => {
                    if(response.msg === "logintrue") {
                        store.user = {
							email: this.email,
							password: this.password,
							id: response.userid,
							isAdmin: response.isAdmin,
							isCampaignMgr: response.isCampaignMgr
						};
                        window.sessionStorage.setItem('uid', store.user.id); //for F5 retrieval
                        window.sessionStorage.setItem('token', store.user.password); //for F5 retrieval
                        window.sessionStorage.setItem('isadmin', store.user.isAdmin); //for F5 retrieval
                        window.sessionStorage.setItem('iscampaignmgr', store.user.isCampaignMgr); //for F5 retrieval
						self.$router.push('/app')
						store.showLoader = false
                    }
					else {
						self.showKalertError({message: 'Login error'});
						store.showLoader = false;
					}
                })
            }
        },
        get_password() {
            store.showLoader = true;
            if( this.email === "" ) {
				store.showLoader = false
                this.showKalertError( { message: "Please enter your email address in the email input box" } )
                return false;
            }

            this.simplePost( `${store.urlroot}post/user-password-reminder.php`, {
                func: "forgetPwd",
                email: this.email
            })
            .then(( response ) => {
                this.showKalertError({ message: response })
				store.showLoader = false
            })
            .finally(() => {
                store.showLoader = false
            })
        }
    },
	watch: {

    },
	mounted() {
        this.show_form = true
	},
    created() {
        store.clients = []
        store.campaign_list = []
        localStorage.clear();
    }
}
</script>

<style scoped>
main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #706f6f;
}

.login-box {
    background-color: #fff;
    border-radius: 30px;
    max-width: 500px;
    width: 100%;
    padding: 70px 50px;
}

.logo {
    text-align: center;
    margin: 0 0 50px
}

.logo img {
    user-drag: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

.form-field:not(:last-child) {
    margin: 0 0 20px;
}

.form-field label {
    display: block;
    font-weight: 500;
}

.form-field input {
    width: 100%;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
}

.form-field button {
    margin: 0 0 10px;
}
</style>