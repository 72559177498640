<template>
    <div v-if="store.selected_campaign > 0" class="downloads">
        <button @click="download_excel" download="">Download Excel</button>
    </div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import axios from "axios";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Downloads",
    data() {
        return {
            store: store
        }
    },
    mixins: [globalMixin],
    methods: {
        download_excel() {
			let data = {vue: true, campaignid: store.selected_campaign, uid: store.user.id, token: store.user.password}
			let headers = { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
			return axios
				.post(`${store.urlroot}post/get-excel.php`, data, headers)
				.then(response => this.download(response))
        },
        download ( content ) {
            let fileLink = document.createElement('a');
            fileLink.href = content.data.location;
            fileLink.setAttribute('download', content.data.filename);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    },
    watch: {

    }
}
</script>

<style scoped>
.downloads {
    text-align: right;
}

.downloads button {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    margin: 0 5px 20px;
    padding: 12px 20px;
    background-color: #e30613;
    border: 2px solid #e30613;
    outline: none;
    cursor: pointer;
}
</style>