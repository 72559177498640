import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://portal.airtimeplus.com.au/'; //api call url
if (window.location.origin.indexOf('localhost') > -1) { //ml
	isDevServer = true;
	urlroot = 'http://glennonvue.test/';
}

export const store = reactive({
	urlroot: urlroot,
	isDevServer: isDevServer,

	sessionOk: true,
	user: {
		id: 0,
		email: '',
		password: '',
		isAdmin: 0,
		isCampaignMgr: 0,
	},

	kalert: {
		shown: false,
		message: null,
		type: 'danger',
	},

	showLoader: false,

	modal_client_data: {
		client: {},
		lastYearClient: {},
	},
	prospects: [],
	clients: [],
	campaign_list: [],
	selected_campaign: null,
	industry_category: [],
	sessions: [],
	reps: [],
	presentation_outcomes: [
		{id: 5405, title: "No"},
		{id: 5406, title: "50%"},
		{id: 5407, title: "80%"},
		{id: 5408, title: "Program Sold"}
	],
	filters: {
		text: "",
		reps: [],
		session: [],
		category: []
	}
})