<template>
    <div class="client-modal">
        <div>
            <div class="modal-header">
                <h3>{{store.modal_client_data[active_info.id].title}}</h3>
                <div v-if="store.modal_client_data.hasLastYearInfo">
                    <a class="button button-gray" href="javascript:void(0)" @click="switch_info">View {{active_info.button}}</a>
                </div>
            </div>
            <div class="modal-body">
                <div class="details">
                    <div class="details-item">
						<div class="form-field">
							<label for="contact_person" class="font-weight-bold">Contact Person</label>
							<input id="contact_person" :disabled="formDisabled" type="text" v-model="store.modal_client_data[active_info.id].firstName" />
						</div>
<!--                        <p><span class="font-weight-bold">Contact Person:</span><br />{{store.modal_client_data[active_info.id].firstName}} {{store.modal_client_data[active_info.id].lastName}}</p>-->
                        <p><span class="font-weight-bold">Phone:</span><br /><a :href="`tel:${store.modal_client_data[active_info.id].phone}`">{{store.modal_client_data[active_info.id].phone}}</a></p>
                        <p><span class="font-weight-bold">Email:</span><br /><a v-if="store.modal_client_data[active_info.id].email" :href="`mailto:${store.modal_client_data[active_info.id].email}`">{{store.modal_client_data[active_info.id].email}}</a></p>
                        <p><span class="font-weight-bold">Industry:</span><br />{{store.modal_client_data[active_info.id].industryCategory}}</p>
                        <p>{{store.modal_client_data[active_info.id].address_physical}}<br />{{store.modal_client_data[active_info.id].city_physical}} {{store.modal_client_data[active_info.id].postcode_physical}}</p>
                    </div>
                    <div class="details-item">
						<p><span class="font-weight-bold">Account Manager:</span></p>
                        <div class="form-field">
                            <select v-model="store.modal_client_data[active_info.id].repOwner" :disabled="formDisabled">
                                <option>--- Choose Rep ---</option>
                                <option v-for="rep in store.reps" :key="rep.id" :value="rep">{{rep.title}}</option>
                            </select>
                        </div>
						<p><span class="font-weight-bold">Session:</span></p>
                        <div class="form-field">
                            <select v-model="store.modal_client_data[active_info.id].attendingSession" :disabled="formDisabled">
								<option>--- Choose Session ---</option>
                                <option v-for="session in store.sessions" :key="session.id" :value="session">{{session.title}}</option>
                            </select>
                        </div>
                        <div class="form-field">
                            <p><input type="checkbox" :disabled="formDisabled" name="has_been_called" v-model="store.modal_client_data[active_info.id].hasBeenCalled" :true-value="1" :false-value="0" /> <span>Client has been called</span></p>
                            <p><input type="checkbox" :disabled="formDisabled" name="has_confirmed" v-model="store.modal_client_data[active_info.id].hasConfirmed" :true-value="1" :false-value="0" /> <span>Confirmed attendance</span></p>
                            <p><input type="checkbox" :disabled="formDisabled" name="req1on1" v-model="store.modal_client_data[active_info.id].req1on1" :true-value="1" :false-value="0" /> <span>Requested 1 on 1 meet</span></p>
                            <p><input type="checkbox" :disabled="formDisabled" name="business_contacted" v-model="store.modal_client_data[active_info.id].bus_contacted" :true-value="1" :false-value="0" /> <span>Business contacted</span></p>
                            <p><input type="checkbox" :disabled="formDisabled" name="meeting_confirmed" v-model="store.modal_client_data[active_info.id].mtg_confirmed" :true-value="1" :false-value="0" /> <span>Meeting confirmed</span></p>
                        </div>
                    </div>
                    <div class="details-item">
                        <div class="form-field">
                            <label for="delegate_name" class="font-weight-bold">Delegate Name</label>
                            <input id="delegate_name" :disabled="formDisabled" type="text" v-model="store.modal_client_data[active_info.id].nameDelegate" />
                        </div>
                        <div class="form-field">
                            <label for="delegate_phone" class="font-weight-bold">Delegate Phone</label>
                            <input id="delegate_phone" :disabled="formDisabled" type="text" v-model="store.modal_client_data[active_info.id].phoneDelegate" />
                        </div>
                        <div class="form-field">
                            <label for="delegate_email" class="font-weight-bold">Delegate Email</label>
                            <input id="delegate_email" :disabled="formDisabled" type="email" v-model="store.modal_client_data[active_info.id].emailDelegate" />
                        </div>
                    </div>
                    <div class="details-item">
                        <div class="form-field">
                            <label for="presentation_outcome" class="font-weight-bold">Presentation Outcome</label>
                            <select id="presentation_outcome" v-model="store.modal_client_data[active_info.id].pres_outcome" :disabled="formDisabled">
                                <option v-for="presentation in store.presentation_outcomes" :key="presentation.id" :value="presentation.title">{{presentation.title}}</option>
                            </select>
                        </div>
                        <div class="form-field">
                            <label for="notes" class="font-weight-bold">Notes</label>
                            <textarea id="notes" v-model="store.modal_client_data[active_info.id].notes" rows="7" :disabled="formDisabled"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="button button-red" href="javascript:void(0)" @click="save_client">Save</a>
                <a class="button button-gray" href="javascript:void(0)" @click="close_modal">Close</a>
            </div>
        </div>
    </div>
</template>

<script>
import {store} from "@/store";
import {globalMixin} from "@/mixins";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Modal",
    mixins: [globalMixin],
    data() {
        return {
            store: store,
            active_info: {
                id: 'client',
                button: 'Last Year Info'
            }
        }
    },
	computed: {
		formDisabled() {
			if(this.active_info.id === 'lastYearClient') return true;
			return false;
		},
	},
    methods: {
        close_modal() {
            document.querySelector(`.client-modal`).classList.remove('show')
			this.setViewToThisYear(); //reset to default
        },
		setViewToThisYear() {
			this.active_info = {id: 'client', button: 'Last Year Info'}
		},
		setViewToLastYear() {
			this.active_info = {id: 'lastYearClient', button: 'Current Info'}
		},
        switch_info() {
            if(this.active_info.id === 'client') this.setViewToLastYear()
			else this.setViewToThisYear()
        },
        save_client() {
            store.showLoader = true
			this.updateClient();
            store.showLoader = false
			this.close_modal();
        }
    },
    watch: {
        'store.modal_client_data': {
            deep: true,
            handler() {

            }
        }
    },
    created() {}
}
</script>

<style scoped>
.form-field label {
    display: block;
    margin: 0 0 10px;
}
.form-field input:not([type='checkbox']),
.form-field textarea {
    min-width: 200px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
}
.form-field input:not([type='checkbox']) {
    height: 40px;
}
.form-field select {
    border-radius: 10px;
}
.client-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.2);
}
.client-modal.show {
    visibility: visible;
    opacity: 1;
}
.client-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    background-color: #f4f4f4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    width: calc(100% - 30px);
    min-height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3aba6f;
    padding: 20px 30px;
}
.modal-header h3 {
    color: #fff;
}
.modal-header .button {
    font-size: 10px;
    height: 40px;
    padding: 0 20px;
}
.modal-body {
    font-size: 14px;
    padding: 50px 30px 20px 30px;
}
.modal-body .form-field:not(:last-child) {
    margin: 0 0 10px
}
.modal-body .details {
    display: flex;
    justify-content: space-between;
}
.modal-body .details > div:not(:last-child) {
    margin-right: 20px;
}
.modal-footer {
	display: flex;
	justify-content: flex-end;
	padding: 10px 10px 20px 10px;
}
.modal-footer a {
	width: 140px;
	margin-left: 20px;
}
</style>